.title{
    text-align: center;
    color:white;
    font-family: 'DM Sans', sans-serif;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 65px;
    letter-spacing: -2px;
}

.subtitle{
    text-align: center;
    color:white;
    font-family: 'DM Sans', sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 65px;
    text-decoration: none;
    letter-spacing: -2px;
}

.title-div{
    width: 75%;
    margin: auto;
    height: 135px;
}

.subtitle-div{
    width: 60%;
    margin: auto;
    height: 135px;
}